/**
 * This is the initial loader for all js applications.
 */
(function() {
	/** browser independent isArray()*/
	Array.isArray = Array.isArray ||
	function(obj) {
		return String(obj.constructor) == Array.toString();
	};

	/** browser independent trim()*/
	if ( typeof String.prototype.trim !== 'function') {
		String.prototype.trim = function() {
			return this.replace(/^\s+|\s+$/g, '');
		};
	}
	
	/** in case the browser doesn't support startsWith*/
	if (!String.prototype.startsWith) {
		String.prototype.startsWith = function(searchString, position) {
			position = position || 0;
			return this.indexOf(searchString, position) === position;
		};
	}

	/** create the root name space */
	var et = et || {
	};

	/** get specified page parameter encoded as query string - with hash only*/
    et.getPageParameter = function(p_name) {
        var locSearch = window.location.search;
        var locHash = window.location.hash;
        if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
            locSearch = "" + window.location;
            locSearch = locSearch.substr(locSearch.indexOf('?'));
        }
        p_name = p_name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
        var regexS = "[\\?&]" + p_name + "=([^&#]*)";
        var regex = new RegExp(regexS);
        
        var loc = ""; 
        if (locSearch) {
        	loc = locSearch;
        } else if (locHash) {
        	loc = locHash;		
        }
        
        var results = regex.exec(loc);
        if (results == null) {
            return "";
        } else {
            var ret = decodeURIComponent(results[1].replace(/\+/g, " "));
            return ret;
        }

    }; 
   
   /**
    * get the regular(not in hash) query string parameters.
    */
    et.getQueryParameter = function(name) {
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"), results = regex.exec(window.location);
        return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    }; 
    
    et.getCurrentHash = function(){
    	var path = window.location.href;
		var d = path.split('/');
		var action = d[d.length-1];
        return action;
    };


	/** returns debug value from the url parameter, either hash or search string */
	et.getDebug = function() {
		var isDebug = et.getPageParameter("debug");
		// look for hash string
		if (!isDebug) {// look for search query string
			var regex = new RegExp("[\\?&]debug=([^&#]*)");
			var results = regex.exec(window.location.search);
			if (results === null) {
				return "";
			} else {
				return decodeURIComponent(results[1].replace(/\+/g, " "));
			}
		}
		return isDebug;
	};

	et.debug = et.getDebug();

	/** for production DEBUG will set to false to disable the console logging*/
	window._console = window.console;
	if (!et.debug || !window.console) {
		window.console = {
			log : function() {
			},
			error : function() {
			},
			debug : function() {
			},
			warn : function() {
			}
		};
	}

	/** constants */
	//TODO the version will be updated by CruiseControl
	et.version = "${VERSION}.${BUILD_NUMBER}";
	et.cookiePrefix = "et.";
	et.cookiePath = "/";
	et.isEmbedded = false;


	/** modules definition*/
	var m_modules = {
		"3rd.multipleSelects" : ["common/lib/multipleSelects/prettify.js","common/lib/multipleSelects/bootstrap-multiselect.js"],
		"3rd.caret" : ["common/lib/caret/jquery.caret.js"],
		"3rd.atjs" : ["common/lib/atjs/css/jquery.atwho.css","common/lib/atjs/js/jquery.atwho.js"],
		"3rd.ckeditor" : ["common/lib/ckeditor/ckeditor.js"],
		"3rd.colorpicker" : ["common/lib/colorpicker/dist/css/bootstrap-colorpicker.min.css","common/lib/colorpicker/dist/js/bootstrap-colorpicker.min.js"],
		"3rd.carousel" : ["common/lib/carousel/slick.css","common/lib/carousel/slick.min.js"],   
		"3rd.jquery.core" : ["common/lib/jquery/jquery-2.2.4.min.js"],
		"3rd.jquery.cookie" : ["common/lib/jqueryplugin/jquery.cookie.js"],
		"3rd.bootstrap" : ["common/lib/bootstrap/bootstrap.min.js", "common/lib/bootstrap/dropzone.min.js"],
		"3rd.bootstrap.wizard" : ["common/lib/jqueryplugin/jquery.bootstrap.wizard.min.js"],
		"3rd.bootstrap.tagsinput" : ["common/lib/bootstrapplugin/bootstrap-tagsinput.css", "common/lib/bootstrapplugin/bootstrap-tagsinput.js"],
		"3rd.nouislider": ["common/lib/nouislider/nouislider.min.css", "common/lib/nouislider/nouislider.min.js"],
		"3rd.jquery.jvalidation" : ["common/lib/jqueryplugin/jquery.validate.min.js", "common/lib/jqueryplugin/additional-methods.min.js", "common/script/validation.methods.js"],
		"3rd.jquery.datepicker" : ["common/lib/jqueryplugin/bootstrap-datepicker.js"],
		"3rd.jquery.typeahead" : ["common/lib/jqueryplugin/typeahead.bundle.js"],
		"3rd.jquery.charts" : ["common/lib/jqueryplugin/Chart.min.js"],
		"3rd.jquery.sortable" : ["common/lib/jqueryplugin/jquery.sortable.min.js"],
        "3rd.jquery.slidebars" : ["common/lib/jqueryplugin/slidebars.min.js"],
		"3rd.nifty" : ["common/lib/nifty/nifty.js"],
		"3rd.pwd" : ["common/lib/jqueryplugin/jquery.password.strength.js"],
		"3rd.jqGrid" : ["common/lib/jqGrid/css/ui.jqgrid.css", "common/lib/jqGrid/js/jquery.jqGrid.min.js", "common/lib/jqGrid/js/i18n/grid.locale-en.js", "common/lib/jqGrid/plugins/grid.common.js", "common/lib/jqGrid/plugins/grid.formedit.js", "common/lib/jqGrid/jqModal.js", "common/lib/jqGrid/plugins/jquery.tablednd.js"],
		"3rd.ace" : ["common/lib/ace/ace.js"],
		"3rd.slidebar" : ["common/lib/slidebar/slidebars.min.js", "common/lib/slidebar/slidebars.min.css"],
		"3rd.dataTables" : ["common/lib/dataTables/jquery.dataTables.min.css", "common/lib/dataTables/scroller.dataTables.min.css", "common/lib/dataTables/dataTables.responsive.css", "common/lib/dataTables/jquery.dataTables.min.js", "common/lib/dataTables/dataTables.responsive.js", "common/lib/dataTables/dataTables.scroller.min.js", "common/lib/dataTables/dataTables.custom.js"],
		"3rd.snapsvg" :["common/lib/snapsvg/snap.svg-min.js"],
		"3rd.areyousure" :["common/lib/jqueryplugin/jquery.are-you-sure.js"],
		"3rd.google.jsapi" : ["https://www.google.com/jsapi"],
		"et.binding" : ["common/lib/rivetsjs/rivets.min.js", "common/script/binding.js"],
		"et.i18n" : ["common/script/locale_full.js", "common/script/currency_res.js"],
		"et.i18n.widgets" : ["common/script/locale_widgets.js"],
		"et.seatmap.widget" : ["https://media-qa.paciolan.corp/seatmap/v3/script/seatmap.js"],
		"et.core" : ["common/script/common.js"],
		"et.ui" : ["common/script/widget.js"],
		"et.analytics" : ["common/script/pa.js"],
		"3rd.jquery.ui" : ["common/lib/jqueryui/jquery-ui.js", "common/lib/jqueryui/jquery-ui-i18n.min.js"],
        "3rd.jquery.jlayout" : ["common/lib/jqueryplugin/jquery.sizes.js", "common/lib/jqueryplugin/jlayout.border.js", "common/lib/jqueryplugin/jquery.jlayout.js"],
        "3rd.pqgrid" : ["common/lib/pqgrid/pqgrid.min.js"],
        "3rd.dynatree" : ["common/lib/dynatree/jquery.dynatree.min.js"],
        "3rd.codemirror" : ["common/lib/codemirror/lib/codemirror.js", "common/lib/codemirror/mode/css/css.js", "common/lib/codemirror/mode/xml/xml.js", "common/lib/codemirror/mode/htmlmixed/htmlmixed.js", "common/lib/codemirror/mode/javascript/javascript.js", "common/lib/codemirror/mode/groovy/groovy.js", "common/lib/codemirror/css/codemirror.css", "common/lib/codemirror/theme/neat.css", "common/lib/codemirror/addon/edit/matchbrackets.js", "common/lib/codemirror/addon/selection/active-line.js", "common/lib/codemirror/util/formatting.js"],
        "3rd.pdfjs" : ["common/lib/pdfjs/jquery.media.js", "common/lib/pdfjs/jquery.metadata.js"],
		"et.sys.serverstatus" : ["sys/serverstatus/global/script/module.js", "sys/serverstatus/global/controller/workspace.js", "sys/serverstatus/global/presenter/shell.js", "sys/serverstatus/global/presenter/topmenu.js", "sys/serverstatus/monitor/presenter/monitor.js", "sys/serverstatus/monitor/presenter/inventory.js"],
        "et.sys.eventseatconv" : ["sys/eventseatconv/global/script/module.js", "sys/eventseatconv/global/controller/workspace.js", "sys/eventseatconv/global/presenter/shell.js", "sys/eventseatconv/global/presenter/topmenu.js", "sys/eventseatconv/conv/presenter/conv.js"],
        "et.sys.pdichannelmgmt" : ["sys/pdichannelmgmt/global/script/module.js", "sys/pdichannelmgmt/global/controller/workspace.js", "sys/pdichannelmgmt/global/presenter/shell.js", "sys/pdichannelmgmt/global/presenter/topmenu.js", "sys/pdichannelmgmt/pdi/presenter/pdi.js"],
        "et.sys.pdiscripteditor" : ["sys/pdiscripteditor/global/script/module.js", "sys/pdiscripteditor/global/controller/workspace.js", "sys/pdiscripteditor/global/presenter/shell.js", "sys/pdiscripteditor/global/presenter/topmenu.js", "sys/pdiscripteditor/pdi/presenter/pdi.js"],
        "et.sys.pdioutboundmonitor" : ["sys/pdioutboundmonitor/global/script/module.js", "sys/pdioutboundmonitor/global/controller/workspace.js", "sys/pdioutboundmonitor/global/presenter/shell.js", "sys/pdioutboundmonitor/global/presenter/topmenu.js", "sys/pdioutboundmonitor/pdi/presenter/pdi.js"],
        "et.sys.pdibatchmonitor" : ["sys/pdibatchmonitor/global/script/module.js", "sys/pdibatchmonitor/global/controller/workspace.js", "sys/pdibatchmonitor/global/presenter/shell.js", "sys/pdibatchmonitor/global/presenter/topmenu.js", "sys/pdibatchmonitor/pdi/presenter/pdi.js"],
        "et.sys.pdibatchmonitordetails" : ["sys/pdibatchmonitordetails/global/script/module.js", "sys/pdibatchmonitordetails/global/controller/workspace.js", "sys/pdibatchmonitordetails/global/presenter/shell.js", "sys/pdibatchmonitordetails/global/presenter/topmenu.js", "sys/pdibatchmonitordetails/pdi/presenter/pdi.js"],
        "et.sys.pdiglobalscripteditor" : ["sys/pdiglobalscripteditor/global/script/module.js", "sys/pdiglobalscripteditor/global/controller/workspace.js", "sys/pdiglobalscripteditor/global/presenter/shell.js", "sys/pdiglobalscripteditor/global/presenter/topmenu.js", "sys/pdiglobalscripteditor/pdi/presenter/pdi.js"],
        "et.sys.pdifullexport" : ["sys/pdimassload/global/script/module.js", "sys/pdimassload/global/controller/workspace.js", "sys/pdimassload/global/presenter/shell.js", "sys/pdimassload/global/presenter/topmenu.js", "sys/pdimassload/pdi/presenter/pdi_full.js"],
        "et.sys.pdipartialexport" : ["sys/pdimassload/global/script/module.js", "sys/pdimassload/global/controller/workspace.js", "sys/pdimassload/global/presenter/shell.js", "sys/pdimassload/global/presenter/topmenu.js", "sys/pdimassload/pdi/presenter/pdi.js"],
        "et.sys.pdirequestqueue" : ["sys/pdirequestqueue/global/script/module.js", "sys/pdirequestqueue/global/controller/workspace.js", "sys/pdirequestqueue/global/presenter/shell.js", "sys/pdirequestqueue/global/presenter/topmenu.js", "sys/pdirequestqueue/pdi/presenter/pdi.js"],
        
        "3rd.extendext" : ["common/lib/extendext/jQuery.extendext.min.js"],
        "3rd.doT" : ["common/lib/doT/doT.min.js"],
        "3rd.querybuilder" : ["common/lib/querybuilder/js/query-builder.js"],
        "3rd.moment" : ["common/lib/querybuilder/js/moment.min.js"],
		"3rd.moment.timezone" : ["common/lib/moment/moment-timezone-with-data.min.js"],
		"3rd.big.integer" : ["common/lib/biginteger/bigInteger.min.js"],

        "3rd.jquery.formatCurrency" : ["common/lib/jqueryplugin/jquery.formatCurrency.js"],
		"et.usi" : ["common/script/usi.js"]
	};

	/** browser independent endsWith()*/
	String.prototype.endsWith = function(p_suffix) {
		return this.indexOf(p_suffix, this.length - p_suffix.length) !== -1;
	};

	/** load list of css files*/
	et.loadCSS = function(p_cssFiles) {
		if (!p_cssFiles)
			return;
		for (var i = 0; i < p_cssFiles.length; i++) {
			$("<link/>", {
				rel : "stylesheet",
				type : "text/css",
				href : p_cssFiles[i] + "?v=" + et.version
			}).appendTo("head");
		}

	};

	/** load scripts. if handler specified then load the scripts asynchronously, otherwise load synchronously*/
	et.loadScript = function(p_names, p_handler, p_errHandler) {
		if (p_handler) {
			et.loadScript.all = [];
			et.loadScript.load = 0;
			//calculate the number of files need to load
			for (var i = 0; i < p_names.length; i++) {
				if (Array.isArray(p_names[i])) {
					for (var j = 0; j < p_names[i].length; j++) {
						var file = p_names[i][j];
						et.loadScript.all.push(et.getMinJs(file, true));
					}
				} else {
					var jsFiles = m_modules[p_names[i]];
					if(!jsFiles) console.log(p_names[i] + " not found");
					if(!jsFiles.loaded) {
						for (var j = 0; j < jsFiles.length; j++) {
							et.loadScript.all.push(et.getMinJs(jsFiles[j]));
						}
						//mark it as loaded
						jsFiles.loaded = true;
					}else{console.log(p_names[i] + " has been loaded. Ignore.");}
				}
			}

			//load all required js files
			m_loadJS(et.loadScript.all, 0, p_handler, p_errHandler);
		}
	};

	/** create name space*/
	et.provide = function(p_name) {
		p_name = /^et\./.test(p_name) ? p_name : "et." + p_name;
		var s = p_name.split('.');
		var parent = window;
		for (var i = 0; i < s.length; i++) {
			parent = m_createNS(parent, s[i]);
		}
		return parent;
	};

	/** load the module with init container*/
	et.loadModule = function(p_module, p_container) {
		p_module.onLoad(p_container);
	};

	/** get the minified version of the javscript file*/
	et.getMinJs = function(p_file, rel) {
		//disable js minify for the time being, except for customize codes.
		if ((false && !et.debug && !/.*common\/lib\/.*/.test(p_file) && !/^http.*/.test(p_file) && !/.*\.min\.js($|\?.*)/.test(p_file)) || (!et.debug && /.*customize\/.*/.test(p_file) && !/.*\.min\.js($|\?.*)/.test(p_file))) {
			var file = p_file.replace(/\.js/, '.min.js');
			if (rel === true) {
				file = new String(file);
				file.rel = rel;
			}

			return file;
		}
		if (rel === true) {
			p_file = new String(p_file);
			p_file.rel = rel;
		}
		return p_file;
	};

	/** get all page parameters encoded as query string*/
    et.getPageParameters = function() {
        var locHash = window.location.search;
        if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
            locHash = "" + window.location;
            locHash = locHash.substring(locHash.indexOf('#'));
        }

        var inx = locHash.indexOf("?");
        if (inx > -1) {
            var args = locHash.substr(inx + 1).split('&');
            var argsParsed = {
            };
            for ( i = 0; i < args.length; i++) {
                arg = args[i];

                if (arg.indexOf('=') == -1) {
                    argsParsed[arg.trim()] = true;
                } else {
                    kvp = arg.split('=');
                    argsParsed[kvp[0].trim()] = decodeURIComponent(kvp[1].trim());
                }
            }
            return argsParsed;
        }
    }; 


	/*************************************************/

	/**internal function to load javascript files*/
	var m_loadJS = function(p_jsFiles, p_index, p_handler, p_errHandler) {
		//no files need to load, possibly loaded already.
		if(p_jsFiles.length == 0) {
			p_handler.call();
			return;
		}
		//all files loaded.
		if (p_jsFiles.length == p_index)
			return;
			
		var js = document.createElement('script');
		js.type = "text/javascript";
		if (!et.rootContext) {
			et.rootContext = "~{url.prefix}";
		}
		var rawPath = p_jsFiles[p_index];
		if(rawPath.endsWith(".css")) {
			var css = [];
			css.push(et.rootContext + rawPath);
			et.loadCSS(css);
			m_loadJS(p_jsFiles, ++p_index, p_handler);
			return;
		}
		if (rawPath.match(/^(http|https)/)) {
			// external js file.
			js.src = rawPath;
		} else {
			js.src = rawPath.rel ? rawPath + "?v=" + et.version : et.rootContext + rawPath + "?v=" + et.version;
		}
		if (js.readyState) {
			// IE
			js.onreadystatechange = function() {
				return function() {
					if (this.readyState == "loaded" || this.readyState == "complete") {
						this.onreadystatechange = null;

						console.log(this.src + " loaded.");
						if (p_jsFiles.length == (p_index + 1)) {
							//loaded all js files.
							p_handler.call();
						} else {
							m_loadJS(p_jsFiles, ++p_index, p_handler);
						}
					}
				};
			}();

			js.onerror = function() {
				console.log(this.src + " failed to load.");
				this.onreadystatechange = null;
				if (p_errHandler) {
					p_errHandler.call();
				} else {
					p_handler.call();
				}
			};
		} else {
			// Others
			js.onload = function() {
				return function() {
					console.log(this.src + " loaded.");
					if (p_jsFiles.length == (p_index + 1)) {
						//loaded all js files.
						p_handler.call();
					} else {
						m_loadJS(p_jsFiles, ++p_index, p_handler);
					}
				};
			}();

			js.onerror = function() {
				console.log(this.src + " failed to load.");
				this.onreadystatechange = null;
				if (p_errHandler) {
					p_errHandler.call();
				} else {
					p_handler.call();
				}

			};
		}
		document.getElementsByTagName("head")[0].appendChild(js);
	};

	/** internal function to create name space*/
	var m_createNS = function(p_parent, p_childName) {
		p_parent[p_childName] = p_parent[p_childName] || {
		};
		//this is to backward compatible with pac7 customization codes
		if(p_parent == window.et && p_childName == 'customize'){
			window['customize'] = p_parent[p_childName];
		}
		return p_parent[p_childName];
	};

	var m_XMLHttpFactories = [
	function() {
		return new XMLHttpRequest();
	},
	function() {
		return new ActiveXObject("Msxml2.XMLHTTP");
	},
	function() {
		return new ActiveXObject("Msxml3.XMLHTTP");
	},
	function() {
		return new ActiveXObject("Microsoft.XMLHTTP");
	}];
	var m_createXMLHTTPObject = function() {
		var xmlhttp = false;
		for (var i = 0; i < m_XMLHttpFactories.length; i++) {
			try {
				xmlhttp = m_XMLHttpFactories[i]();
			} catch (e) {
				continue;
			}
			break;
		}
		return xmlhttp;
	};

	/** expose et */
	window.et = et;

})();
